//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoadingSpinner from '@/components/LoadingSpinner'
export default {
    created() {
        this.isLoading = true;
        this.getCurrentState();
        setInterval(() =>{
            this.getCurrentState();
        },5000);
    },
    components: {
        LoadingSpinner
    },
    props: [
        'request_to_id', 
        'name'
    ],
    data() {
        return {
            isLoading: true,
            texts: [
                'You already sent a request to ' + this.name + '.',
                this.name + ' want to add you to their friend list.',
                'You accepted the request of ' + this.name,
                'You are not friends with ' + this.name + '. If you know ' + this.name + ', you can add them as your friend.'
            ],
            state: 0,
            currentText: ''
        }
    },
    methods: {
        getCurrentState() {
            this.$http({
                url: 'relations/status/' + this.request_to_id,
                method: 'GET'
            }).then((response) => {
                this.isLoading = false;
                if (response.data['status'] == "active_request") {
                    this.state = 0;
                    this.currentText = this.texts[this.state];
                } else if (response.data['status'] == "pending_request") {
                    this.state = 1;
                    this.currentText = this.texts[this.state];
                } else if (response.data['status'] == "accepted_request") {
                    window.location.reload();
                } else {
                    this.state = 3;
                    this.currentText = this.texts[this.state];
                }
            }).catch(()=>{
                this.isLoading = false;
            });
        },
        sendFriendRequest(id) {
            this.$http({
                url: 'relations/send/' + id,
                method: 'POST'
            }).then((response) => {
                if (response.data['status'] == 'request_sent') {
                    this.$q.notify({
                        message: "A friend request has been successfully sent.",
                        color: 'positive',
                        icon: 'group_add'
                    })
                    this.state = 0;
                    this.currentText = this.texts[this.state];
                } else if (response.data['status'] == 'has_active_request') {
                    this.$q.notify({
                        message: "You already sent a friend request to this user. Please wait for the other user to accept you.",
                        color: 'primary',
                        icon: 'group'
                    })    
                    this.state = 0;
                    this.currentText = this.texts[this.state];                
                } else {
                    this.$q.notify({
                        message: "There was an error while trying to send the friend request.",
                        color: "negative"
                    })
                }
            })            
        },
        cancelFriendRequest(id) {
            this.$http({
                url: 'relations/cancel/' + id,
                method: 'POST'
            }).then((response) => {
                if (response.data['status'] == "cancel_success") {
                    this.$q.notify({
                        message: 'You have cancelled the request.',
                        color: 'negative'
                    });
                    this.state = 3;
                    this.currentText = this.texts[this.state];
                }
            });
        },
        acceptFriendRequest(id) {
            this.$http({
                url: 'relations/accept/' + id,
                method: 'POST'
            }).then((response) => {
                if (response.data['status'] == "request_accepted") {
                    window.location.reload();
                }
            });   
        },
        declineFriendRequest(id) {
               this.$http({
                url: 'relations/cancel/' + id,
                method: 'POST'
            }).then((response) => {
                if (response.data['status'] == "cancel_success") {
                    window.location.reload();
                }
            });            
        }
    }
}
