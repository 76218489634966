//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "LoadingSpinner",
    props: ['label', 'size', 'kapal']
}
